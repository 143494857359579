import {
	ACCOUNT_ID_KEY,
	EMAIL_KEY,
	EXTERNAL_ID_KEY,
	LOGGED_KEY,
	NAME_KEY,
	SURNAME_KEY,
	TOKEN_KEY,
} from '../../constants'
import type { AuthOrchestratorData } from '../../types/auth'
import type { Session } from '../../types/session'
import { sessionCache } from '../sessionCache'
import { setLegacyToken } from '../setLegacyToken'

export const setSessionCacheStorage = async (
	sessionData: AuthOrchestratorData | Session
): Promise<void> => {
	try {
		const { logged, token, externalId, email, name, surname, accountId } =
			sessionData

		await Promise.all([
			sessionCache.addKeyToCache(EMAIL_KEY, email ?? null),
			sessionCache.addKeyToCache(NAME_KEY, name ?? null),
			sessionCache.addKeyToCache(SURNAME_KEY, surname ?? null),
			sessionCache.addKeyToCache(EXTERNAL_ID_KEY, externalId ?? null),
			sessionCache.addKeyToCache(TOKEN_KEY, token),
			sessionCache.addKeyToCache(LOGGED_KEY, logged),
			sessionCache.addKeyToCache(ACCOUNT_ID_KEY, accountId ?? null),
		])

		if (token) {
			// TODO: Legacy token for dataManager on micros
			setLegacyToken(token)
		}
	} catch (err) {
		throw new Error('[Session] Error to store session data on CacheStorage')
	}
}
